import React, { useRef, useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase/config";

const ProductForm = ({ onAddProduct }) => {
  const [itemCode, setItemCode] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [currentColor, setCurrentColor] = useState("");
  const [colorImages, setColorImages] = useState([]); // renamed from currentImages
  const [colorOptions, setColorOptions] = useState([]);
  const canvasRef = useRef(null);

  const popularColors = [
    "Red",
    "Green",
    "Blue",
    "Yellow",
    "Purple",
    "Orange",
    "Pink",
    "Black",
    "White",
    "Original",
    "RGB",
  ];

  const handleMainImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100000) {
      // 100KB
      const reader = new FileReader();
      reader.onloadend = () => setMainImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      alert("File size should be less than 100KB.");
    }
  };

  const handleColorImageUpload = (e) => {
    const files = Array.from(e.target.files).filter(
      (file) => file.size <= 100000
    );

    if (files.length !== e.target.files.length) {
      alert("Some files exceeded the 100KB size limit and were not uploaded.");
    }

    const readers = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Base64 encoding
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readers).then((base64Images) => setColorImages(base64Images));
  };
  const handleAddColorOption = () => {
    if (!currentColor || colorImages.length === 0) {
      alert("Please select a color and upload images.");
      return;
    }
    if (colorOptions.some((option) => option.value === currentColor)) {
      alert("This color is already added.");
      return;
    }

    setColorOptions([
      ...colorOptions,
      { value: currentColor, imgSrc: colorImages },
    ]);
    setCurrentColor("");
    setColorImages([]);
  };

  const handleRemoveColorOption = (index) => {
    setColorOptions(colorOptions.filter((_, i) => i !== index));
  };

  const downloadImage = async (imgSrc, filename) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    return new Promise((resolve) => {
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL("image/jpeg", 0.9); // Save as JPEG with quality 0.9

        // Create a link to download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = filename.replace(".png", ".jpg"); // Ensure filename ends in .jpg
        link.click();

        resolve();
      };
      image.src = imgSrc;
    });
  };

  const handleAddProduct = async () => {
    if (!itemCode || colorOptions.length === 0) {
      alert("Please complete all fields before adding the product.");
      return;
    }

    const newProduct = {
      itemCode,
      imgSrc: `${itemCode}.jpg`, // Main image will be saved as .jpg
      color: colorOptions.map((option) => ({
        value: option.value,
        imgSrc: option.imgSrc.map(
          (_, index) => `${itemCode}-${option.value}-${index + 1}.jpg` // Save color images as .jpg
        ),
      })),
      timestamp: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, "productImages"), newProduct);
      alert("Product added successfully!");

      // Download the main image
      if (mainImage) {
        await downloadImage(mainImage, `${itemCode}.jpg`); // Save main image as .jpg
      }

      // Download each color option image using Base64 data from colorOptions
      for (const option of colorOptions) {
        for (let i = 0; i < option.imgSrc.length; i++) {
          const base64Image = option.imgSrc[i]; // This is the actual Base64 data
          const filename = `${itemCode}-${option.value}-${i + 1}.jpg`; // Save color images as .jpg
          await downloadImage(base64Image, filename);
        }
      }

      setItemCode("");
      setMainImage(null);
      setColorOptions([]);
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product. Please try again.");
    }
  };

  return (
    <div
      className="p-6 mt-4 max-w-3xl mx-auto overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor"
      style={{ maxHeight: "800px" }}
    >
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Add New Product</h2>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <div className="flex flex-col space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
          <label className="text-gray-700 font-medium">Item Code</label>
          <input
            type="text"
            placeholder="Enter item code"
            value={itemCode}
            onChange={(e) => setItemCode(e.target.value)}
            className="col-span-2 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
          <label className="text-gray-700 font-medium">Main Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleMainImageUpload}
            className="col-span-2 p-3 border border-gray-300 rounded-md"
          />
        </div>

        {mainImage && (
          <div className="flex justify-center mt-4">
            <img
              src={mainImage}
              alt="Main Product"
              className="h-40 w-auto rounded-md shadow-md"
            />
          </div>
        )}

        <div className="space-y-4">
          <label className="text-gray-700 font-medium">Select Color</label>
          <select
            value={currentColor}
            onChange={(e) => {
              setCurrentColor(e.target.value);
              setColorImages([]);
            }}
            className="p-3 border border-gray-300 rounded-md w-80 pb-4"
          >
            <option value="">Select Color</option>
            {popularColors.map((color, index) => (
              <option key={index} value={color}>
                {color}
              </option>
            ))}
          </select>

          <label className="text-gray-700 font-medium">Color Images</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleColorImageUpload}
            className="p-3 border border-gray-300 rounded-md w-80"
          />

          <button
            onClick={handleAddColorOption}
            className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
          >
            Add Color
          </button>
        </div>

        {colorOptions.length > 0 && (
          <div
            className="mt-6 space-y-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor"
            style={{ maxHeight: "200px" }}
          >
            {colorOptions.map((option, index) => (
              <div key={index} className="border p-4 rounded-md shadow-md">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold text-lg">{option.value}</h3>
                  <button
                    onClick={() => handleRemoveColorOption(index)}
                    className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
                <div className="flex space-x-4 overflow-x-auto">
                  {option.imgSrc.map((img, i) => (
                    <img
                      key={i}
                      src={img}
                      alt={`${option.value}-${i}`}
                      className="w-20 h-20 object-cover rounded-md shadow-md"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <button
          onClick={handleAddProduct}
          className="w-full py-3 bg-green-600 text-white rounded-md hover:bg-green-500 mt-4"
        >
          Add Product
        </button>
      </div>
    </div>
  );
};

export default ProductForm;
