import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, updateDoc, arrayUnion, getDoc, setDoc } from "firebase/firestore";

import { useSelector, useDispatch } from "react-redux";
import { db } from "../../firebase/config";
import { resetCart } from "../../redux/IchthusSlice";
import useGetData from "../../CustomHooks/useGetData";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Add loading state
  const userID = useSelector((state) => state.orebiReducer.userID);
  const { data: users } = useGetData("user");
  const cartProducts = useSelector((state) => state.cart?.products || []);
  const currentUser = users?.find((user) => user.id === userID);

  useEffect(() => {
    const checkUserAndSaveOrder = async () => {
      console.log("checkUserAndSaveOrder called");

      if (!currentUser) {
        console.log("No user found, exiting function");
        // If the currentUser hasn't been loaded yet, wait
        return;
      }

      const referenceNumber = new URLSearchParams(window.location.search).get(
        "ref"
      );
      console.log("Reference number from URL:", referenceNumber);

      if (!referenceNumber) {
        toast.error("No reference number found.");
        console.log("Reference number missing, exiting function");
        setLoading(false);
        return;
      }

      try {
        console.log("Attempting to save order data");

        const orderData = {
          referenceNumber,
          products: cartProducts,
          timestamp: new Date(),
          orderStatus: "To Ship",
        };

        console.log("Order data to be saved:", orderData);

        const userOrderDocRef = doc(db, "ordered", currentUser.id);
        let userOrderDoc = await getDoc(userOrderDocRef);

        if (!userOrderDoc.exists()) {
          console.log(
            "No existing order document for user, creating new document"
          );
          await setDoc(userOrderDocRef, { orders: [] });
          userOrderDoc = await getDoc(userOrderDocRef);
        }

        const existingOrders = userOrderDoc.data()?.orders || [];
        const orderExists = existingOrders.some(
          (order) => order.referenceNumber === referenceNumber
        );

        if (!orderExists) {
          console.log("Saving new order");
          await updateDoc(userOrderDocRef, {
            orders: arrayUnion(orderData),
          });

          console.log("Order saved successfully", orderData);

          // Log the order data to the console
          console.log("Order data:", orderData);

          // Reset the cart
          dispatch(resetCart());

          toast.success("Order saved successfully.");
        } else {
          console.log("Order with this reference number already exists");
          toast.error("Order with this reference number already exists.");
        }
      } catch (error) {
        console.error("Error saving order:", error);
        toast.error("Error saving order data.");
      } finally {
        setLoading(false); // Stop loading after saving
      }
    };

    checkUserAndSaveOrder();
  }, [currentUser, cartProducts, dispatch]);

  useEffect(() => {
    if (!currentUser && !loading) {
      console.log("No user logged in after checking");
      toast.error("No user is logged in. Please log in to continue.");
      setLoading(false);
    }
  }, [currentUser, loading]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while processing
  }

  return (
    <div className="text-center mt-10">
      <h1 className="text-3xl font-bold">PAYMENT SUCCESSFUL!</h1>
      <p className="mt-4">
        Thank you for your purchase. Your order has been placed successfully.
      </p>
      <button
        className="mt-6 bg-blue-500 text-white py-2 px-4 rounded"
        onClick={() => navigate("/shop")}
      >
        Shop More
      </button>
    </div>
  );
};

export default PaymentSuccess;
