import React, { createContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn } from "./IchthusSlice";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(INITIAL_STATE.currentUser));
  }, []); // Removed INITIAL_STATE.currentUser from the dependency array

  return (
    <AuthContext.Provider value={{ isLoggedIn, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
