import { useState, useEffect, useCallback } from "react";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import LZString from "lz-string";

// Session flag for checking if data has already been fetched
const isSessionCached = (key) =>
  localStorage.getItem(`session-${key}`) !== null;
const setSessionCacheFlag = (key) =>
  localStorage.setItem(`session-${key}`, true);

// IndexedDB helper functions
const openIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open("MyDatabase", 2);
    dbRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("DataStore")) {
        db.createObjectStore("DataStore", { keyPath: "key" });
      }
    };
    dbRequest.onsuccess = (event) => resolve(event.target.result);
    dbRequest.onerror = (event) => reject(event.target.error);
  });
};

const cacheInIndexedDB = async (key, data) => {
  try {
    const db = await openIndexedDB();
    const transaction = db.transaction(["DataStore"], "readwrite");
    const store = transaction.objectStore("DataStore");
    store.put({ key, data });
  } catch (error) {
    console.error("Error caching in IndexedDB:", error);
  }
};

const getFromIndexedDB = async (key) => {
  try {
    const db = await openIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["DataStore"], "readonly");
      const store = transaction.objectStore("DataStore");
      const getRequest = store.get(key);
      getRequest.onsuccess = () => resolve(getRequest.result?.data);
      getRequest.onerror = () => reject(null);
    });
  } catch (error) {
    console.error("Error getting from IndexedDB:", error);
    return null;
  }
};

// Data compression and caching logic
const cacheData = (key, data, ttl = 3600) => {
  const compressedData = LZString.compress(JSON.stringify(data));
  const expirationTime = Date.now() + ttl * 1000;

  if (compressedData.length < 5000) {
    localStorage.setItem(
      key,
      JSON.stringify({ data: compressedData, expirationTime })
    );
  } else {
    cacheInIndexedDB(key, compressedData);
  }
};

const getCachedData = async (key) => {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const { data, expirationTime } = JSON.parse(cachedData);
    if (Date.now() > expirationTime) {
      localStorage.removeItem(key);
      return null;
    }
    return JSON.parse(LZString.decompress(data));
  }
  const indexedDBData = await getFromIndexedDB(key);
  return indexedDBData ? JSON.parse(LZString.decompress(indexedDBData)) : null;
};

// Improved useGetData hook
const useGetData = (collectionName, isStatic = false, useListener = false) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cacheKey = `useGetData-${collectionName}`;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const cachedData = await getCachedData(cacheKey);

      // Use cache if session data is already loaded and isStatic is true
      if (isStatic && cachedData && isSessionCached(cacheKey)) {
        setData(cachedData);
        setLoading(false);
        return;
      }

      if (useListener) {
        const unsubscribe = onSnapshot(
          collection(db, collectionName),
          (snapshot) => {
            const newData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setData(newData);
            setSessionCacheFlag(cacheKey); // Set session flag after initial load
          }
        );
        return () => unsubscribe();
      } else {
        const snapshot = await getDocs(collection(db, collectionName));
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(newData);

        if (isStatic) {
          cacheData(cacheKey, newData);
          setSessionCacheFlag(cacheKey); // Set session flag after initial load
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [collectionName, isStatic, useListener, cacheKey]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch: fetchData };
};

export default useGetData;
