// ParentComponent.js
import React from "react";
import ProductDisplay from "./ProductDisplay";

const ParentComponent = () => {
  return (
    <div>
      <ProductDisplay />
    </div>
  );
};

export default ParentComponent;
