import React, { useEffect, useState } from "react";
import ProductForm from "./ProductForm";
import useGetData from "../../../CustomHooks/useGetData";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase/config";

const ProductDisplay = () => {
  const { data: productData, loading: productDataLoading } = useGetData(
    "productImages",
    false,
    true
  );
  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("recent"); // State for sorting
  const itemsPerPage = 6;

  useEffect(() => {
    if (productData && !productDataLoading) {
      setProducts(productData);
    }
  }, [productData, productDataLoading]);

  const handleAddProduct = (newProduct) => {
    setProducts((prevProducts) => [...prevProducts, newProduct]);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const productToDelete = products.find(
        (product) => product.id === productId
      );
      if (!productToDelete) {
        alert("Product not found.");
        return;
      }

      const docRef = doc(db, "productImages", productToDelete.id);
      await deleteDoc(docRef);

      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
      alert("Product deleted successfully!");
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete product. Please try again.");
    }
  };

  const sortProducts = (products) => {
    const sortedProducts = [...products];
    if (sortOption === "ascending") {
      return sortedProducts.sort((a, b) =>
        a.itemCode.localeCompare(b.itemCode)
      );
    }
    if (sortOption === "descending") {
      return sortedProducts.sort((a, b) =>
        b.itemCode.localeCompare(a.itemCode)
      );
    }
    if (sortOption === "recent") {
      return sortedProducts.sort((a, b) => b.timestamp - a.timestamp); // Sort by timestamp
    }
    return products;
  };

  const filteredProducts = products.filter((product) =>
    String(product.itemCode)?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedProducts = sortProducts(filteredProducts);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = sortedProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div className="p-6 rounded-lg shadow-lg">
      <h1 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-center text-gray-700 leading-tight font-raleway mb-8">
        Product Images
      </h1>

      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by Item Code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full max-w-lg"
        />

        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="border border-gray-300 p-2 rounded-md"
        >
          <option value="recent">Recent Uploaded</option>
          <option value="ascending">Item Code: Ascending</option>
          <option value="descending">Item Code: Descending</option>
        </select>
      </div>

      <button
        onClick={() => setShowForm(true)}
        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500"
      >
        Add Product
      </button>

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg z-20">
            <button
              onClick={() => setShowForm(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <ProductForm onAddProduct={handleAddProduct} />
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        {currentProducts && currentProducts.length > 0 ? (
          currentProducts.map((product, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-700">
                Item Code: {product.itemCode}
              </h3>
              <img
                src={`/images/${product.itemCode}.jpg`}
                alt="Main Product"
                className="w-24 h-24 object-cover mt-2"
              />
              <div className="mt-2">
                {product.color?.map((colorOption, idx) => (
                  <div
                    key={idx}
                    className="mt-2 border p-4 rounded-md shadow-md"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="text-lg font-medium text-gray-700">
                        Color: {colorOption.value}
                      </h4>
                    </div>
                    <div className="flex space-x-2 mt-1 overflow-x-auto scrollbar-hide">
                      {colorOption.imgSrc?.map((_, imgIdx) => (
                        <img
                          key={imgIdx}
                          src={`/images/${product.itemCode}-${
                            colorOption.value
                          }-${imgIdx + 1}.jpg`}
                          alt={`${colorOption.value} variant`}
                          className="w-20 h-20 object-cover rounded-md flex-shrink-0"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <button
                onClick={() => handleDeleteProduct(product.id)}
                className="mt-4 px-4 py-2 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-500"
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p>No products available</p>
        )}
      </div>

      <div className="flex justify-center mt-4 space-x-2">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-lg hover:bg-gray-400 disabled:opacity-50"
        >
          Previous
        </button>
        <span className="px-4 py-2 bg-gray-200 rounded-lg">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-lg hover:bg-gray-400 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductDisplay;
